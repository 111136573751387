import React, { useEffect, useState } from 'react';
import {
	NavLink,
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
	useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSpinner,
	faCube,
	faIdCardAlt,
	faUser,
	faUsers,
	faUserCog,
	faUserCircle,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Modules, Rights, Roles, Users } from '../../pages';
import Groups from '../../pages/groups/Groups';
import lockIcon from '../../assets/images/lock.png';
import { capitalizeFirstLetter } from '../../helpers/util';
import headerLayout from './header_layout';

const BaseLayoutWrapper = () => {
	const [showSidebar, setSidebarVisibility] = useState(true);
	const { userDataStatus, userData } = useSelector((state) => state.userData);
	const dispatch = useDispatch();
	const { url } = useRouteMatch();
	const { pathname: pathName } = window.location;
	const uri = pathName.split('/');
	const pages = capitalizeFirstLetter(
		uri[uri.length - 1] == '' ? uri[uri.length - 2] : uri[uri.length - 1]
	);
	const history = useHistory();
	
	let prevLocation = "";

	useEffect(() => {
		const getUserDetails = () => {
			axios
				.get('/api/v1/user/get/details')
				.then((response) => {
					if (response.status === 200) {
						dispatch({
							type: 'update_user_data',
							payload: {
								userData: response?.data ?? {},
								userDataStatus: 'success',
							},
						});
						if (!['/app/roles/', '/app/roles'].includes(pathName)) getRoles();
						if (!['/app/rights/', '/app/rights'].includes(pathName))
							getRights();
						if (!['/app/groups/', '/app/groups'].includes(pathName))
							getGroups();
						if (!['/app/modules/', '/app/modules'].includes(pathName))
							getModules();
					} else
						dispatch({
							type: 'update_user_data_status',
							payload: 'error',
						});
				})
				.catch((error) => {
					console.log(error.response);
					const status = error?.response?.status ?? '';
					if (status === 403) {
						dispatch({
							type: 'update_user_data_status',
							payload: 'forbidden',
						});
					} else if (status === 401) {
						dispatch({
							type: 'update_user_data_status',
							payload: 'unauthorized',
						});
					} else
						dispatch({
							type: 'update_user_data_status',
							payload: 'error',
						});
				});
		};

		getUserDetails();

		if (window?.umApp?.DoOverrideBaseLayoutWrapperComponent) {
			window?.umApp?.DoOverrideBaseLayoutWrapperComponent();
		}	
		history.listen(nextLocation => {
			dispatch({
				type: 'UPDATE_URL_HISTORY',
				payload: nextLocation.pathname,
			});
		});	
	}, []);

	const getRoles = () => {
		axios
			.get('/api/v1/roles/getall')
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: 'UPDATE_ROLES_DATA',
						payload: response?.data?.data ?? [],
					});
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	const getRights = () => {
		axios
			.get('/api/v1/rights/getall')
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: 'UPDATE_RIGHTS_DATA',
						payload: response?.data ?? [],
					});
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	const getGroups = () => {
		axios
			.get('/api/v1/groups/getall')
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: 'UPDATE_GROUPS_DATA',
						payload: response?.data ?? [],
					});
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	const getModules = () => {
		axios
			.get('/api/v1/modules/getall')
			.then((response) => {
				if (response.status === 200) {
					let dataflat = [];
					const takeOutChild = (child) => {
						child.forEach((val) => {
							dataflat.push(val);
							if (val.child) {
								takeOutChild(val.child);
							}
						});
					};
					response.data.forEach((val) => {
						dataflat.push(val);
						if (val.child) {
							takeOutChild(val.child);
						}
					});
					dispatch({
						type: 'UPDATE_MODULES_DATA',
						payload: {
							data: response?.data ?? [],
							dataflat,
						},
					});
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	if (userDataStatus === 'loading') {
		return (
			<div className='sc-section'>
				<h2>
					<FontAwesomeIcon className='mr-10' icon={faSpinner} spin />
					Loading..
				</h2>
			</div>
		);
	}

	if (userDataStatus === 'unauthorized') {
		return null;
	}

	if (userDataStatus === 'forbidden') {
		return (
			<div className='sc-section'>
				<img src={lockIcon} alt='lock icon' width={70} />
				<h2 className='mt-10'>Access Denied</h2>
				<p>
					You don't have permission to access{' '}
					<b>
						<i>User Management</i>
					</b>
					.<br />
					Contact an administrator to get permissions.
				</p>
			</div>
		);
	}

	const getUserFullName = () => {
		const { first_name = '', last_name = '' } = userData ?? {};
		let name = 'Username';
		if (first_name) {
			name = first_name;
			if (last_name) {
				name += ` ${last_name}`;
			}
		}
		return `${name}`;
	};

	const getUserRole = () => {
		const { roles } = userData ?? {};
		if (roles && roles.length === 0) {
			return 'Unknown';
		} else if (roles && roles.length === 1) {
			return roles?.[0]?.label ?? 'Unknown';
		} else return 'Unknown';
	};

	const handleMenuActiveState = () => {
		setSidebarVisibility((prevState) => !prevState);
	};

	const handleLogout = () => {
		if (window?.umApp?.logout) {
			window?.umApp?.logout();
		} else alert('Please configure logout functionality');
	};

	const getHeaderLayout = ()=>{
		return headerLayout({
			key: `header-layout`,
			component: `header-layout`
		});
	}

	const {
		mainApp: { isHeader, logoUrl },
		groups,
		hideSidebar
	} = window['runConfig'];

	return (
		<div className='app'>
			<div className='app-header' style={{display: isHeader?'block':'none'}}>
				{/* <div className='app-header-wrapper'>
					<div className='app-brand'>
						<img src={logoUrl} width='100' alt='User Management'></img>
					</div>
					<div className='app-content-header'>
						<div className='user-dropdown'>
							<FontAwesomeIcon
								title='User'
								icon={faUserCircle}
								aria-label='user'
								className='user-icon cursor-pointer'
							/>
							<div className='user-dropdown-content'>
								<div className='user-detail-section'>
									<p className='user-name'>{getUserFullName()}</p>
									<p className='user-role'>{getUserRole()}</p>
								</div>
								<div className='links-section'>
									<p className='cursor-pointer' onClick={handleLogout}>
										Logout
									</p>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				{getHeaderLayout()}
				<div className='app-header-breadcrumb'>
					<p>
						User Management / <b>{pages=="Groups"?groups?.title??'groups':pages}</b>
					</p>
				</div>
			</div>
			<div className='app-content-wrapper'>
				{!hideSidebar&&(<div className={showSidebar ? 'app-sidebar' : 'app-sidebar active'}>
					<div className='app-sidebar-header'>
						<p>User Management</p>
						<FontAwesomeIcon
							icon={faTimes}
							className='close-icon'
							onClick={handleMenuActiveState}
						/>
					</div>
					<ul className='app-navbar-list'>
						<li>
							<NavLink activeClassName='active' to={`${url}/users/`}>
								<span>
									<FontAwesomeIcon
										title='Menu'
										icon={faUser}
										className='menu-icon'
									/>
									Users
								</span>
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName='active' to={`${url}/roles/`}>
								<span>
									<FontAwesomeIcon
										title='Menu'
										icon={faIdCardAlt}
										className='menu-icon'
									/>
									Roles
								</span>
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName='active' to={`${url}/groups/`}>
								<span>
									<FontAwesomeIcon
										title='Menu'
										icon={faUsers}
										className='menu-icon'
									/>
									{groups?.title??"Groups"}
								</span>
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName='active' to={`${url}/rights/`}>
								<span>
									<FontAwesomeIcon
										title='Menu'
										icon={faUserCog}
										className='menu-icon'
									/>
									Rights
								</span>
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName='active' to={`${url}/modules/`}>
								<span>
									<FontAwesomeIcon
										title='Menu'
										icon={faCube}
										className='menu-icon'
									/>
									Modules
								</span>
							</NavLink>
						</li>
					</ul>
				</div>)}
				<div className='app-content'>
					<div className='app-content-block'>
						<div className='app-content-block-inner'>
							<Switch>
								<Route exact path={`${url}`}>
									<Redirect to={`${url}users/`} />
								</Route>
								<Route path={`${url}/users/`}>
									<Users />
								</Route>
								<Route path={`${url}/groups/`}>
									<Groups />
								</Route>
								<Route path={`${url}/modules/`}>
									<Modules />
								</Route>
								<Route path={`${url}/rights/`}>
									<Rights />
								</Route>
								<Route path={`${url}/roles/`}>
									<Roles />
								</Route>
							</Switch>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BaseLayoutWrapper;
